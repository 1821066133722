import React, { useState } from 'react';
import './App.css';
import heroImage from './herrstrittguru.png'; // Replace with your image file
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import RangeSlider from './RangeSlider';

const App = () => {
  const [selectedTextType, setSelectedTextType] = useState('Outline');
  const textTypes = ['Outline']; // Add more text types as needed
  const [currentIndex, setCurrentIndex] = useState(0); // Start with the first text type
  const [selectedChapter, setSelectedChapter] = useState('basics'); // Default to 'basics'
  const [gradeDisplayType, setGradeDisplayType] = useState('810'); // Default to German Unter- and Mittelstufe
  const [authorName, setAuthorName] = useState(''); // State for author's name
  const [reviewerName, setReviewerName] = useState(''); // State for reviewer's name
  const [sliderValues, setSliderValues] = useState({
    aufgabenerfuellung: 60,
    textumgang: 60,
    inhaltlicheraufbau: 60,
    lexikbandbreite: 60,
    lexikkorrektheit: 60,
    grammatikbandbreite: 60,
    grammatikkorrektheit: 60,
    textgestaltungtextaufbau: 60,
    textgestaltungangemessenheit: 60
  });

  const handlePrevClick = () => {
    const newIndex = (currentIndex - 1 + textTypes.length) % textTypes.length;
    setCurrentIndex(newIndex);
    setSelectedTextType(textTypes[newIndex]);
  };

  const handleNextClick = () => {
    const newIndex = (currentIndex + 1) % textTypes.length;
    setCurrentIndex(newIndex);
    setSelectedTextType(textTypes[newIndex]);
  };

  const handleSliderChange = (name) => (event) => {
    setSliderValues({ ...sliderValues, [name]: event.target.value });
  };

  const printRef = React.useRef();
    // Get the current date
    const currentDate = new Date().toLocaleDateString();

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('print.pdf');
  };

  const calculateAverage = () => {
    const total = Object.values(sliderValues).reduce(
      (sum, value) => sum + parseInt(value),
      0
    );
    return (total / Object.values(sliderValues).length).toFixed(2);
  };

  const calculateGermanGrade810 = (average) => {
    const avg = parseFloat(average);

    if (avg >= 95) return '1';
    if (avg >= 90) return '1-';
    if (avg >= 85) return '1-2';
    if (avg >= 80) return '2+';
    if (avg >= 75) return '2';
    if (avg >= 70) return '2-';
    if (avg >= 65) return '2-3';
    if (avg >= 60) return '3+';
    if (avg >= 55) return '3';
    if (avg >= 50) return '3-';
    if (avg >= 45) return '3-4';
    if (avg >= 40) return '4+';
    if (avg >= 35) return '4';
    if (avg >= 30) return '4-';
    if (avg >= 25) return '4-5';
    if (avg >= 20) return '5+';
    if (avg >= 15) return '5';
    if (avg >= 10) return '5-';
    if (avg >= 5) return '5-6';
    return '6';
  };

  const calculateGermanKursstufe = (average) => {
    const avg = parseFloat(average);

    if (avg >= 95) return 15;
    if (avg >= 90) return 14;
    if (avg >= 85) return 13;
    if (avg >= 80) return 12;
    if (avg >= 75) return 11;
    if (avg >= 70) return 10;
    if (avg >= 65) return 9;
    if (avg >= 60) return 8;
    if (avg >= 55) return 7;
    if (avg >= 50) return 6;
    if (avg >= 45) return 5;
    if (avg >= 40) return 4;
    if (avg >= 33) return 3;
    if (avg >= 27) return 2;
    if (avg >= 20) return 1;
    return 0;
  };
  const getSliderMessages = (type) => {
    const defaultMessages = {
      getAufgabenerfuellungMessage: (value) => "No Aufgabenerfuellung message available.",
      getTextumgangMessage: (value) => "No Textumgang message available.",
      getInhaltlicherAufbauMessage: (value) => "No inhaltlicher Aufbau message available.",
    };
  
    switch (type) {
      case 'Outline':
        return {
          getAufgabenerfuellungMessage: (value) => {
            if (value >= 0 && value < 20) {
              return (
                <>
                  <span className="slidermessage-english">Insufficient selection of aspects, lack of task relevance.</span>
                  <br />
                  <span className="slidermessage-german">Unzureichende Auswahl von Aspekten, Aufgabenbezug fehlend.</span>
                </>
              );
            } else if (value >= 20 && value < 40) {
              return (
                <>
                  <span className="slidermessage-english">Hardly considering relevant aspects, hardly appropriate.</span>
                  <br />
                  <span className="slidermessage-german">Kaum relevante Aspekte berücksichtigend, kaum angemessen.</span>
                </>
              );
            } else if (value >= 40 && value < 55) {
              return (
                <>
                  <span className="slidermessage-english">Considering less relevant aspects, however, correct to some extent.</span>
                  <br />
                  <span className="slidermessage-german">Weniger relevante Aspekte berücksichtigend, ansatzweise korrekt.</span>
                </>
              );
            } else if (value >= 55 && value < 70) {
              return (
                <>
                  <span className="slidermessage-english">Taking into account several relevant aspects, essentially correct.</span>
                  <br />
                  <span className="slidermessage-german">Mehrere relevante Aspekte berücksichtigend, im Wesentlichen korrekt.</span>
                </>
              );
            } else if (value >= 70 && value < 85) {
              return (
                <>
                  <span className="slidermessage-english">Considering relevant aspects to a large extent, largely correct.</span>
                  <br />
                  <span className="slidermessage-german">Relevante Aspekte weitgehend berücksichtigend, weitgehend korrekt.</span>
                </>
              );
            } else if (value >= 85 && value <= 100) {
              return (
                <>
                  <span className="slidermessage-english">Comprehensive and correct.</span>
                  <br />
                  <span className="slidermessage-german">Umfassend, korrekt.</span>
                </>
              );
            }
          },
  
          getTextumgangMessage: (value) => {
            if (value >= 0 && value < 20) {
              return (
                <>
                  <span className="slidermessage-english">Not focussed on relevant aspects; where necessary, not abstracting.</span>
                  <br />
                  <span className="slidermessage-german">Nicht auf relevante Aspekte fokussiert; ggf. nicht abstrahierend.</span>
                </>
              );
            } else if (value >= 20 && value < 40) {
              return (
                <>
                  <span className="slidermessage-english">Hardly focussed on relevant aspects; where necessary, hardly abstracting.</span>
                  <br />
                  <span className="slidermessage-german">Kaum auf relevante Aspekte fokussiert; ggf. kaum abstrahierend.</span>
                </>
              );
            } else if (value >= 40 && value < 55) {
              return (
                <>
                  <span className="slidermessage-english">To some extent focussed on relevant aspects; where necessary, rudimentarily abstracting.</span>
                  <br />
                  <span className="slidermessage-german">Ansatzweise auf relevante Aspekte fokussiert; ggf. ansatzweise abstrahierend.</span>
                </>
              );
            } else if (value >= 55 && value < 70) {
              return (
                <>
                  <span className="slidermessage-english">Essentially focussed on relevant aspects; where necessary, mainly abstracting.</span>
                  <br />
                  <span className="slidermessage-german">Im Wesentlichen auf relevante Aspekte fokussiert; ggf. im Wesentlichen abstrahierend.</span>
                </>
              );
            } else if (value >= 70 && value < 85) {
              return (
                <>
                  <span className="slidermessage-english">Largely focussed on relevant aspects; where necessary, largely abstracting.</span>
                  <br />
                  <span className="slidermessage-german">Weitgehend auf relevante Aspekte fokussiert; ggf. weitgehend abstrahierend.</span>
                </>
              );
            } else if (value >= 85 && value <= 100) {
              return (
                <>
                  <span className="slidermessage-english">Consistently focussed on relevant aspects; where necessary, highly abstracting.</span>
                  <br />
                  <span className="slidermessage-german">Durchgängig auf relevante Aspekte fokussiert; ggf. in hohem Maße abstrahierend.</span>
                </>
              );
            }
          },


          getInhaltlicherAufbauMessage: (value) => {
            if (value >= 0 && value < 20) {
              return (
                <>
                  <span className="slidermessage-english">Unstructured, redundant, incomprehensible, incoherent.</span>
                  <br />
                  <span className="slidermessage-german">Unstrukturiert, redundant, nicht nachvollziehbar, inkohärent.</span>
                </>
              );
            } else if (value >= 20 && value < 40) {
              return (
                <>
                  <span className="slidermessage-english">Hardly structured, often redundant, hardly comprehensible or coherent</span>
                  <br />
                  <span className="slidermessage-german">Kaum strukturiert, häufig redundant, kaum nachvollziehbar bzw. kaum noch kohärent.</span>
                </>
              );
            } else if (value >= 40 && value < 55) {
              return (
                <>
                  <span className="slidermessage-english">Structured to some extent, repeatedly redundant, coherent to some extent, still coherent.</span>
                  <br />
                  <span className="slidermessage-german">Ansatzweise strukturiert, wiederholt redundant, ansatzweise schlüssig, noch kohärent.</span>
                </>
              );
            } else if (value >= 55 && value < 70) {
              return (
                <>
                  <span className="slidermessage-english">Essentially structured and redundancy-free, essentially coherent and consistent.</span>
                  <br />
                  <span className="slidermessage-german">Im Wesentlichen strukturiert und redundanzfrei, im Wesentlichen schlüssig und kohärent.</span>
                </>
              );
            } else if (value >= 70 && value < 85) {
              return (
                <>
                  <span className="slidermessage-english">Structured, largely redundancy-free, largely conclusive and coherent.</span>
                  <br />
                  <span className="slidermessage-german">Strukturiert, weitgehend redundanzfrei, weitgehend schlüssig und kohärent.</span>
                </>
              );
            } else if (value >= 85 && value <= 100) {
              return (
                <>
                  <span className="slidermessage-english">Clearly structured, redundancy-free, highly coherent, consistent throughout.</span>
                  <br />
                  <span className="slidermessage-german">Klar strukturiert, redundanzfrei, in hohem Maße schlüssig, durchgängig kohärent.</span>
                </>
              );
            }
          },


          getLexikBandbreiteMessage: (value) => {
            if (value >= 0 && value < 20) {
              return (
                <>
                  <span className="slidermessage-english">Not an appropriate choice of words.</span>
                  <br />
                  <span className="slidermessage-german">Keine angemessene Wortwahl.</span>
                </>
              );
            } else if (value >= 20 && value < 40) {
              return (
                <>
                  <span className="slidermessage-english">Clearly limited choice of words.</span>
                  <br />
                  <span className="slidermessage-german">Deutlich eingeschränkte Wortwahl.</span>
                </>
              );
            } else if (value >= 40 && value < 55) {
              return (
                <>
                  <span className="slidermessage-english">Restricted, still appropriate choice of words.</span>
                  <br />
                  <span className="slidermessage-german">Eingeschränkte, noch angemessene Wortwahl.</span>
                </>
              );
            } else if (value >= 55 && value < 70) {
              return (
                <>
                  <span className="slidermessage-english">Essentially precise and variable choice of words.</span>
                  <br />
                  <span className="slidermessage-german">Im Wesentlichen präzise und variable Wortwahl.</span>
                </>
              );
            } else if (value >= 70 && value < 85) {
              return (
                <>
                  <span className="slidermessage-english">Largely precise and variable choice of words; some phrases typical of the target language.</span>
                  <br />
                  <span className="slidermessage-german">Weitgehend präzise und variable Wortwahl; teilweise zielsprachentypische Wendungen.</span>
                </>
              );
            } else if (value >= 85 && value <= 100) {
              return (
                <>
                  <span className="slidermessage-english">Highly precise and variable choice of words; phrases typical of the target language.</span>
                  <br />
                  <span className="slidermessage-german">In hohem Maße präzise und variable Wortwahl; zielsprachentypische Wendungen.</span>
                </>
              );
            }
          },


          getLexikKorrektheitMessage: (value) => {
            if (value >= 0 && value < 20) {
              return (
                <>
                  <span className="slidermessage-english">Lexically incorrect; no longer comprehensible.</span>
                  <br />
                  <span className="slidermessage-german">Lexikalisch unkorrekt; Verständlichkeit nicht mehr gegeben.</span>
                </>
              );
            } else if (value >= 20 && value < 40) {
              return (
                <>
                  <span className="slidermessage-english">Often lexically incorrect; comprehensibility severely impaired.</span>
                  <br />
                  <span className="slidermessage-german">Häufig lexikalisch unkorrekt; Verständlichkeit stark beeinträchtigt.</span>
                </>
              );
            } else if (value >= 40 && value < 55) {
              return (
                <>
                  <span className="slidermessage-english">Partially lexically incorrect; comprehensibility impaired in places.</span>
                  <br />
                  <span className="slidermessage-german">Teilweise lexikalisch unkorrekt; Verständlichkeit stellenweise beeinträchtigt.</span>
                </>
              );
            } else if (value >= 55 && value < 70) {
              return (
                <>
                  <span className="slidermessage-english">Essentially lexically correct; slightly limited comprehensibility.</span>
                  <br />
                  <span className="slidermessage-german">Im Wesentlichen lexikalisch korrekt; Verständlichkeit geringfügig eingeschränkt.</span>
                </>
              );
            } else if (value >= 70 && value < 85) {
              return (
                <>
                  <span className="slidermessage-english">Largely lexically correct; comprehensibility not impaired by individual inaccuracies.</span>
                  <br />
                  <span className="slidermessage-german">Weitgehend lexikalisch korrekt; Verständlichkeit durch einzelne Ungenauigkeiten nicht beeinträchtigt.</span>
                </>
              );
            } else if (value >= 85 && value <= 100) {
              return (
                <>
                  <span className="slidermessage-english">Highly lexically correct; comprehensible throughout.</span>
                  <br />
                  <span className="slidermessage-german">In hohem Maße lexikalisch korrekt; durchgängig verständlich.</span>
                </>
              );
            }
          },

          
          
          
          
          
          
         
          
          
          
          




          getGrammatikBandbreiteMessage: (value) => {
            if (value >= 0 && value < 20) {
              return (
                <>
                  <span className="slidermessage-english">Sentence structure and structures inadequate.</span>
                  <br />
                  <span className="slidermessage-german">Satzbau und Strukturen unzulänglich.</span>
                </>
              );
            } else if (value >= 20 && value < 40) {
              return (
                <>
                  <span className="slidermessage-english">Sentence structure and structures barely adequate.</span>
                  <br />
                  <span className="slidermessage-german">Satzbau und Strukturen kaum angemessen.</span>
                </>
              );
            } else if (value >= 40 && value < 55) {
              return (
                <>
                  <span className="slidermessage-english">Less variable sentence structure; still appropriate structures.</span>
                  <br />
                  <span className="slidermessage-german">Wenig variabler Satzbau; noch angemessene Strukturen.</span>
                </>
              );
            } else if (value >= 55 && value < 70) {
              return (
                <>
                  <span className="slidermessage-english">Essentially variable sentence structure; essentially functional use of the chosen structures.</span>
                  <br />
                  <span className="slidermessage-german">Im Wesentlichen variabler Satzbau; im Wesentlichen funktionaler Einsatz der gewählten Strukturen.</span>
                </>
              );
            } else if (value >= 70 && value < 85) {
              return (
                <>
                  <span className="slidermessage-english">Largely variable sentence structure; largely functional use of the chosen structures.</span>
                  <br />
                  <span className="slidermessage-german">Weitgehend variabler Satzbau; weitgehend funktionaler Einsatz der gewählten Strukturen.</span>
                </>
              );
            } else if (value >= 85 && value <= 100) {
              return (
                <>
                  <span className="slidermessage-english">Highly variable sentence structure; consistently functional use of the chosen structures.</span>
                  <br />
                  <span className="slidermessage-german">In hohem Maße variabler Satzbau; durchgängig funktionaler Einsatz der gewählten Strukturen.</span>
                </>
              );
            }
          },

          getGrammatikKorrektheitMessage: (value) => {
            if (value >= 0 && value < 20) {
              return (
                <>
                  <span className="slidermessage-english">Grammatically incorrect; no longer comprehensible.</span>
                  <br />
                  <span className="slidermessage-german">Grammatikalisch unkorrekt; Verständlichkeit nicht mehr gegeben.</span>
                </>
              );
            } else if (value >= 20 && value < 40) {
              return (
                <>
                  <span className="slidermessage-english">Often grammatically incorrect; comprehensibility severely impaired.</span>
                  <br />
                  <span className="slidermessage-german">Häufig grammatikalisch unkorrekt; Verständlichkeit stark beeinträchtigt.</span>
                </>
              );
            } else if (value >= 40 && value < 55) {
              return (
                <>
                  <span className="slidermessage-english">Partially grammatically incorrect; comprehensibility impaired in places.</span>
                  <br />
                  <span className="slidermessage-german">Teilweise grammatikalisch unkorrekt; Verständlichkeit stellenweise beeinträchtigt.</span>
                </>
              );
            } else if (value >= 55 && value < 70) {
              return (
                <>
                  <span className="slidermessage-english">Essentially grammatically correct; comprehensibility slightly impaired.</span>
                  <br />
                  <span className="slidermessage-german">Im Wesentlichen grammatikalisch korrekt; Verständlichkeit geringfügig eingeschränkt.</span>
                </>
              );
            } else if (value >= 70 && value < 85) {
              return (
                <>
                  <span className="slidermessage-english">Largely grammatically correct; individual inaccuracies do not impair comprehensibility.</span>
                  <br />
                  <span className="slidermessage-german">Weitgehend grammatikalisch korrekt; einzelne Ungenauigkeiten beeinträchtigen die Verständlichkeit nicht.</span>
                </>
              );
            } else if (value >= 85 && value <= 100) {
              return (
                <>
                  <span className="slidermessage-english">Highly grammatically correct; comprehensible throughout.</span>
                  <br />
                  <span className="slidermessage-german">In hohem Maße grammatikalisch korrekt; durchgängig verständlich.</span>
                </>
              );
            }
          },




          getTextgestaltungTextaufbauMessage: (value) => {
            if (value >= 0 && value < 20) {
              return (
                <>
                  <span className="slidermessage-english">No focussed text, no means of structuring the text.</span>
                  <br />
                  <span className="slidermessage-german">Kein zielgerichteter Text, keine textstrukturierenden Mittel.</span>
                </>
              );
            } else if (value >= 20 && value < 40) {
              return (
                <>
                  <span className="slidermessage-english">Hardly purposeful text, hardly any text structuring means (cohesion).</span>
                  <br />
                  <span className="slidermessage-german">Kaum zielgerichteter Text, kaum textstrukturierende Mittel (Kohäsion).</span>
                </>
              );
            } else if (value >= 40 && value < 55) {
              return (
                <>
                  <span className="slidermessage-english">Rudimentarily purposeful text with limited functional use of text structuring devices (cohesion).</span>
                  <br />
                  <span className="slidermessage-german">Ansatzweise zielgerichteter Text mit eingeschränkt funktionalem Einsatz textstrukturierender Mittel (Kohäsion).</span>
                </>
              );
            } else if (value >= 55 && value < 70) {
              return (
                <>
                  <span className="slidermessage-english">Essentially focussed text with predominantly functional use of text-structuring devices (cohesion).</span>
                  <br />
                  <span className="slidermessage-german">Im Wesentlichen zielgerichteter Text mit überwiegend funktionalem Einsatz textstrukturierender Mittel (Kohäsion).</span>
                </>
              );
            } else if (value >= 70 && value < 85) {
              return (
                <>
                  <span className="slidermessage-english">Largely focussed text with functional use of text structuring devices (cohesion).</span>
                  <br />
                  <span className="slidermessage-german"> Weitgehend zielgerichteter Text mit funktionalem Einsatz textstrukturierender Mittel (Kohäsion).</span>
                </>
              );
            } else if (value >= 85 && value <= 100) {
              return (
                <>
                  <span className="slidermessage-english">Highly focussed text with a consistently functional use of text-structuring devices (cohesion).</span>
                  <br />
                  <span className="slidermessage-german">In hohem Maße zielgerichteter Text mit durchgängig funktionalem Einsatz textstrukturierender Mittel (Kohäsion).</span>
                </>
              );
            }
          },
 
          getTextgestaltungAngemessenheitMessage: (value) => {
            if (value >= 0 && value < 20) {
              return (
                <>
                  <span className="slidermessage-english">Lack of realisation of the required linguistic text type characteristics; no use of language appropriate to the situation and addressee; no independent linguistic design.</span>
                  <br />
                  <span className="slidermessage-german">Fehlende Umsetzung der geforderten sprachlichen Textsortenmerkmale; keine situations- und adressatengerechte Sprachverwendung; keine eigenständige sprachliche Gestaltung.</span>
                </>
              );
            } else if (value >= 20 && value < 40) {
              return (
                <>
                  <span className="slidermessage-english">Hardly any realisation of the required linguistic text type characteristics; hardly any use of language appropriate to the situation and target audience; little independent linguistic design.</span>
                  <br />
                  <span className="slidermessage-german">Kaum Umsetzung der geforderten sprachlichen Textsortenmerkmale; kaum situations- und adressatengerechte Sprachverwendung; wenig eigenständige sprachliche Gestaltung.</span>
                </>
              );
            } else if (value >= 40 && value < 55) {
              return (
                <>
                  <span className="slidermessage-english">Rudimentary realisation of the required linguistic text type characteristics; rudimentary use of language appropriate to the situation and target audience; rudimentary independent linguistic composition.</span>
                  <br />
                  <span className="slidermessage-german">Ansatzweise Umsetzung der geforderten sprachlichen Textsortenmerkmale; ansatzweise situations- und adressatengerechte Sprachverwendung; ansatzweise eigenständige sprachliche Gestaltung.</span>
                </>
              );
            } else if (value >= 55 && value < 70) {
              return (
                <>
                  <span className="slidermessage-english">Essentially realisation of the required linguistic text type characteristics; essentially situation- and addressee-appropriate use of language; essentially independent linguistic design.</span>
                  <br />
                  <span className="slidermessage-german">Im Wesentlichen Umsetzung der geforderten sprachlichen Textsortenmerkmale; im Wesentlichen situations- und adressatengerechte Sprachverwendung; im Wesentlichen eigenständige sprachliche Gestaltung.</span>
                </>
              );
            } else if (value >= 70 && value < 85) {
              return (
                <>
                  <span className="slidermessage-english">Extensive realisation of the required linguistic text type characteristics; language use largely appropriate to the situation and target group; largely independent linguistic composition.</span>
                  <br />
                  <span className="slidermessage-german">Weitgehende Umsetzung der geforderten sprachlichen Textsortenmerkmale; weitgehend situations- und adressatengerechte Sprachverwendung; weitgehend eigenständige sprachliche Gestaltung.</span>
                </>
              );
            } else if (value >= 85 && value <= 100) {
              return (
                <>
                  <span className="slidermessage-english">High degree of realisation of the required linguistic characteristics of the text type; high degree of use of language appropriate to the situation and addressee; high degree of independent linguistic composition.</span>
                  <br />
                  <span className="slidermessage-german">In hohem Maße Umsetzung der geforderten sprachlichen Textsortenmerkmale; in hohem Maße situations- und adressatengerechte Sprachverwendung; in hohem Maße eigenständige sprachliche Gestaltung.</span>
                </>
              );
            }
          },
        };
  
      default:
        return defaultMessages;
    }
  };
   

  const sliderMessages = getSliderMessages(selectedTextType);

  // Update both scrolling and state for subnav clicks
  const handleSubnavClick = (sectionId) => {
    setSelectedChapter(sectionId); // Update the selected chapter
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderGradingSection = () => {
    const average = calculateAverage();
    const grade810 = calculateGermanGrade810(average);
    const kursstufeGrade = calculateGermanKursstufe(average);

    return (
      <div>
        {/* Card-like Panel for Grading System */}
        <div className="card">
          <h4><i className="icon-grade"></i> Choose Your Grading System</h4>
          <div className="radio-group">
            <label className="radio-item">
              <input
                type="radio"
                value="810"
                checked={gradeDisplayType === '810'}
                onChange={(e) => setGradeDisplayType(e.target.value)}
              />
              German Unter- and Mittelstufe
            </label>
            <label className="radio-item">
              <input
                type="radio"
                value="kursstufe"
                checked={gradeDisplayType === 'kursstufe'}
                onChange={(e) => setGradeDisplayType(e.target.value)}
              />
              German Kursstufe
            </label>
            <label className="radio-item">
              <input
                type="radio"
                value="percentages"
                checked={gradeDisplayType === 'percentages'}
                onChange={(e) => setGradeDisplayType(e.target.value)}
              />
              Show only Percentages
            </label>
          </div>
        </div>

        {/* Collapsible Card for Additional Information */}
        <div className="card collapsible">
          <h4><i className="icon-info"></i> Additional Information</h4>
          <div className="field-group">
            <label>
              Author's Name (optional):
              <input
                type="text"
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
                placeholder="Enter Author's Name"
              />
            </label>
          </div>
          <div className="field-group">
            <label>
              Reviewer's Name (optional):
              <input
                type="text"
                value={reviewerName}
                onChange={(e) => setReviewerName(e.target.value)}
                placeholder="Enter Reviewer's Name"
              />
            </label>
          </div>
        </div>




        <h2>Content (Inhalt)</h2>
        <RangeSlider
          label="Task Fulfillment (Aufgabenerfüllung)"
          value={sliderValues.aufgabenerfuellung}
          onChange={handleSliderChange('aufgabenerfuellung')}
          getMessage={sliderMessages.getAufgabenerfuellungMessage}
        />
        <RangeSlider
          label="Text Handling (Umgang mit dem Text)"
          value={sliderValues.textumgang}
          onChange={handleSliderChange('textumgang')}
          getMessage={sliderMessages.getTextumgangMessage}
        />
        <RangeSlider
          label="Composition of Content - Structure, stringency, coherence (Inhaltlicher Aufbau - Struktur, Stringenz, Kohärenz)"
          value={sliderValues.inhaltlicheraufbau}
          onChange={handleSliderChange('inhaltlicheraufbau')}
          getMessage={sliderMessages.getInhaltlicherAufbauMessage}
        />

        <h2>Language (Sprache)</h2>
        <h3>Lexical competence (Lexik)</h3>
        <RangeSlider
          label="Lexical range (Lexik - Bandbreite)"
          value={sliderValues.lexikbandbreite}
          onChange={handleSliderChange('lexikbandbreite')}
          getMessage={sliderMessages.getLexikBandbreiteMessage}
        />
        <RangeSlider
          label="Lexical correctness (Lexik - Korrektheit)"
          value={sliderValues.lexikkorrektheit}
          onChange={handleSliderChange('lexikkorrektheit')}
          getMessage={sliderMessages.getLexikKorrektheitMessage}
        />
        <h3>Grammatical competence (Grammatik)</h3>
        <RangeSlider
          label="Grammatical range (Grammatik - Bandbreite)"
          value={sliderValues.grammatikbandbreite}
          onChange={handleSliderChange('grammatikbandbreite')}
          getMessage={sliderMessages.getGrammatikBandbreiteMessage}
        />
        <RangeSlider
          label="Grammatical correctness (Grammatik - Korrektheit)"
          value={sliderValues.grammatikkorrektheit}
          onChange={handleSliderChange('grammatikkorrektheit')}
          getMessage={sliderMessages.getGrammatikKorrektheitMessage}
        />
        <h3>Textgestaltung</h3>
        <RangeSlider
          label="Text composition - Text structure (Textgestaltung - Textaufbau)"
          value={sliderValues.textgestaltungtextaufbau}
          onChange={handleSliderChange('textgestaltungtextaufbau')}
          getMessage={sliderMessages.getTextgestaltungTextaufbauMessage}
        />
        <RangeSlider
          label="Text composition - Appropriateness (Textgestaltung - Angemessenheit)"
          value={sliderValues.textgestaltungangemessenheit}
          onChange={handleSliderChange('textgestaltungangemessenheit')}
          getMessage={sliderMessages.getTextgestaltungAngemessenheitMessage}
        />

        <div className="feedback-sheet" ref={printRef}>
          <h1>Feedback Sheet</h1>
          {authorName && (
            <h2>
              for the {selectedTextType} of {authorName}
            </h2>
          )}
          <h3>Content (Inhalt)</h3>
          <div className="feedback-item">
            <p>{sliderMessages.getAufgabenerfuellungMessage(sliderValues.aufgabenerfuellung)}</p>
          </div>
          <div className="feedback-item">
            <p>{sliderMessages.getTextumgangMessage(sliderValues.textumgang)}</p>
          </div>
          <div className="feedback-item">
            <p>{sliderMessages.getInhaltlicherAufbauMessage(sliderValues.inhaltlicheraufbau)}</p>
          </div>
          <h3>Language (Sprache)</h3>
          <h4>Lexical range and correctness (Lexik - Bandbreite und Korrektheit)</h4>
          <div className="feedback-item">
            <p>{sliderMessages.getLexikBandbreiteMessage(sliderValues.lexikbandbreite)}</p>
          </div>
          <div className="feedback-item">
            <p>{sliderMessages.getLexikKorrektheitMessage(sliderValues.lexikkorrektheit)}</p>
          </div>
          <h4>Grammatical range and correctness (Grammatik - Bandbreite und Korrektheit)</h4>
          <div className="feedback-item">
            <p>{sliderMessages.getGrammatikBandbreiteMessage(sliderValues.grammatikbandbreite)}</p>
          </div>
          <div className="feedback-item">
            <p>{sliderMessages.getGrammatikKorrektheitMessage(sliderValues.grammatikkorrektheit)}</p>
          </div>
          <h4>Text composition - Text structure and appropriateness (Textgestaltung - Textaufbau und Angemessenheit)</h4>
          <div className="feedback-item">
            <p>{sliderMessages.getTextgestaltungTextaufbauMessage(sliderValues.textgestaltungtextaufbau)}</p>
          </div>
          <div className="feedback-item">
            <p>{sliderMessages.getTextgestaltungAngemessenheitMessage(sliderValues.textgestaltungangemessenheit)}</p>
          </div>
          <div className="grade-display">
            <div className="grade-display-left">
            {gradeDisplayType === '810' && (
              <div>
                <h3>Grade: {grade810}</h3>
                <p>Scale: German lower and intermediate level (1-6)</p>
                <p>Average: {average}%</p>
              </div>
            )}
            {gradeDisplayType === 'kursstufe' && (
              <div>
                <h3>Grade: {kursstufeGrade} CP</h3>
                <p>Scale: German course level (0-15 Credit Points)</p>
                <p>Average: {average}%</p>
              </div>
            )}
            {gradeDisplayType === 'percentages' && (
              <div>
                <h3>Average in per cent:</h3>
                <p>{average}%</p>
              </div>
            )}
            </div>
            <div className="grade-display-right">
          <span className="signature">
            {reviewerName && `${reviewerName}`} <br />
            {currentDate}
          </span>
        </div>
          </div>
        </div>
        <button type="button" onClick={handleDownloadPdf}>
            Download this feedback as PDF
          </button>
      </div>
    );
  };

  const renderContentSections = () => {
    const textTypeContent = {
      Outline: {
        basics: (
          <>
          <p>Writing an outline is about <em>giving a structured, general account of something, referring to the relevant elements and omitting details</em>.</p>
          <p>When asked to outline a text, you are often given criteria on the basis of which you need to outline the text. <br /><em>Example: Outline the inner development of the story's protagonist.</em></p>
          <p>Be careful! In English, outlining often represents preparing a text by writing a hierarchical structure of some sort, either numbered or using bullet points. In this outline, however, you are asked to write <em>full, coherent sentences.</em></p>  
          </>
        ),
        structure: (
          <>
            <p>An outline typically is structured with the help of <em>two individual paragraphs</em>.</p>
            <p><strong>Paragraph One</strong> contains an overview about the text itself, e.g. its author, title, publication date, text type, and any other features you may discover. Here, it is also considered to provide in one sentence what the text is "about".</p>
            <p>In <strong>Paragraph Two</strong>, you answer the task you are given. Depending on what you are asked to do, you either sketch out important aspects about the whole text - or about certain aspects of it.</p>
          </>
        ),
        style: (
          <>
          <p>In this specific form of an outline, use <em>full, coherent and structured sentences</em>. Study the following <strong>example task:</strong></p>
          <blockquote><strong><em>Outline the inner development of the wife in Ernest Hemingway's &quot;Cat in the Rain&quot;.</em></strong></blockquote>
          <blockquote><strong>Paragraph one:</strong><em> In &quot;Cat in the rain &quot;, American author Ernest Hemingway presents a very surface-level narrative of an American couple on their holidays in Italy. The short story was first published by Boni & Liveright in 1925 in the short story collection &quot;In Our Time&quot;.</em></blockquote>
          <p>An outline is typically written in the <em>present tense</em>, also called <em>literary present</em>.</p>
          <blockquote><strong>Paragraph two:</strong><em> As the American wife watches the rain, she sees a cat crouched “under one of the dripping green tables.” Feeling sorry for the cat that “was trying to make herself so compact she would not be dripped on,” the wife decides to rescue "that kitty."</em>...</blockquote>
          </>
        ),
        language: (
          <>
          <p>An outline draws from a <em>semi-formal to formal</em> register.</p>
          <p>An important skill to develop when practising writing outlines is to paraphrase. It means, that you find different words to present the ideas of the original text. As seen above, you can quote from the original text, but please do it only sparingly, as it is considered bad style to overuse quotes from the text.</p>
          <p>Linking words are less important in an outline. However, work on finding different ways to start your outline. Often, students start their outlines like this:</p>
          <blockquote><em>&quot;Cat in the Rain&quot; was written by Ernest Hemingway in 1925. It was published by Boni & Liveright. ...</em></blockquote>
          <p>While this approach is not wrong, it is a bit boring. Surprise the reader with a short quote from the story, change the order of the information - everything you can to make your outline appear less &quot;standard&quot;.</p>
          </>
        ),
      },
    };

    const content = textTypeContent[selectedTextType] || {};


    return (
      <div className="maincontent-text" ref={(printRef)}>
        <section id="basics">
          <h3>Basics</h3>
          {content.basics || <p>No content available.</p>}
        </section>
        <section id="structure">
          <h3>Structure</h3>
          {content.structure || <p>No content available.</p>}
        </section>
        <section id="style">
          <h3>Style</h3>
          {content.style || <p>No content available.</p>}
        </section>
        <section id="language">
          <h3>Language</h3>
          {content.language || <p>No content available.</p>}
        </section>
      </div>
    );
  };

  return (
    <div className="App">
      <section className="hero">
        <div className="hero-image">
          <img src={heroImage} alt="Hero" />
        </div>
        <div className="hero-text">
          <h1>Welcome to Mr Stritt's Text Type Handbook.</h1>
          <p>Explore different text types and learn how to structure them effectively.</p>
          <button
            className="hero-call-to-action"
            onClick={() => {
              document.querySelector('.maincontentwrapper').scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Choose Your Text Type
          </button>
        </div>
      </section>

      <div className="maincontentwrapper">
        <div className="article-headline">
          <button className="nav-button prev" onClick={handlePrevClick}>
            &lt;
          </button>

          <div className="article-headlineitem">
            <h2>{selectedTextType}</h2>
          </div>

          <button className="nav-button next" onClick={handleNextClick}>
            &gt;
          </button>
        </div>

        <div className="textcontent">
          <div className="textcontent-subnav">
            <div className="textcontent-subnavitem" onClick={() => handleSubnavClick('basics')}>
              <h3>Basics</h3>
            </div>
            <div className="textcontent-subnavitem" onClick={() => handleSubnavClick('structure')}>
              <h3>Structure</h3>
            </div>
            <div className="textcontent-subnavitem" onClick={() => handleSubnavClick('style')}>
              <h3>Style</h3>
            </div>
            <div className="textcontent-subnavitem" onClick={() => handleSubnavClick('language')}>
              <h3>Language</h3>
            </div>
            <div className="textcontent-subnavitem" onClick={() => handleSubnavClick('grading')}>
              <h3>Grading</h3>
            </div>
          </div>

          {/* This will now switch between sections dynamically */}
          {selectedChapter === 'grading' ? renderGradingSection() : renderContentSections()}


        </div>
      </div>
    </div>
  );
};

export default App;
