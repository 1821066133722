import React from 'react';

const RangeSlider = ({ label, value, onChange, getMessage }) => {
  return (
    <div>
      <label htmlFor={label.toLowerCase()}>{label}</label>
      <input
        type="range"
        id={label.toLowerCase()}
        name={label.toLowerCase()}
        min="0"
        max="100"
        value={value}
        onChange={onChange}
      />
      <div className="result">{value}% - {getMessage(value)}</div>
    </div>
  );
};

export default RangeSlider;



